export const displayPrice = (price) => {
  const numberPrice = Number(price);
  if(isNaN(numberPrice) ) {
    console.log('Cannot add format to price: ', price);
    return price;
  }
  if(price > 1000) {
    const dotIndex = price.indexOf('.');
    if(dotIndex === -1) {
      // NOTE price doesn't have fraction part
      return `${price.slice(0, price.length -3)}’${price.slice(-3)}`;
    } else {
      // NOTE price has fraction part
      return `${price.slice(0, dotIndex -3)}’${price.slice(dotIndex - 3, dotIndex)}.${price.slice(dotIndex, price.length)}`;
    }
  }
  return price;
}
