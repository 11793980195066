import React from 'react';

import Header from './Header';
import Footer from './Footer';
import Layout from './Layout';
import ArtworkContent from './ArtworkContent';
import ListControlsDesktop from './ArtworkContent/ListControlsDesktop';
import { ArtworkContainer } from './ArtworkContent/styledElements';

const Artwork = ({
    artwork,
    assetsPath,
    cdnPath,
    contactEmail,
    documentAssetsPath,
    entryAssetsPath,
    exhibition,
    language,
    listSettings,
     disableArrowNav,
    backPath,
}) => {
    const { nextArtworkSlug, previousArtworkSlug } = listSettings;
    const rightArrowSource = `${entryAssetsPath}/ico_right.svg`;
    const leftArrowSource = `${entryAssetsPath}/ico_left.svg`;

    // const nextArtworkLink = `/${language}/${nextArtworkSlug}`;
    const nextArtworkLink = `/${nextArtworkSlug}`;
    const hasNext = Boolean(nextArtworkSlug);

    // const previousArtworkLink = `/${language}/${previousArtworkSlug}`;
    const previousArtworkLink = `/${previousArtworkSlug}`;
    const hasPrevious = Boolean(previousArtworkSlug);

    return (
        <Layout noImageBg>
            <ArtworkContainer>
                <Header
                    noPadding
                    assetsPath={entryAssetsPath}
                />
                <ArtworkContent
                    artwork={artwork}
                    exhibition={exhibition}
                    assetsPath={assetsPath}
                    cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
                    contactEmail={contactEmail}
                    documentAssetsPath={documentAssetsPath}
                    entryAssetsPath={entryAssetsPath}
                    language={language}
                    listSettings={listSettings}
                    showPrice={exhibition.active}
                    disableArrowNav={disableArrowNav}
                    backPath={backPath}
                />
                <ListControlsDesktop
                    type={'prev'}
                    active={hasPrevious}
                    iconSource={leftArrowSource}
                    link={previousArtworkLink}
                />
                <ListControlsDesktop
                    type={'next'}
                    active={hasNext}
                    iconSource={rightArrowSource}
                    link={nextArtworkLink}
                />
                <Footer
                    exhibition={exhibition}
                    noPadding
                    assetsPath={entryAssetsPath}
                />
            </ArtworkContainer>
        </Layout>
    );
};

export default Artwork;
