import React from 'react';

import {
    ImageFooterElementContainer,
    ImageFooterImg,
} from './styledElements';

const ImageFooterElement = ({ active, onClick, src }) => (
    <ImageFooterElementContainer
        active={active}
        onClick={onClick}
    >
        <ImageFooterImg
            src={src}
            active={active}
        />
    </ImageFooterElementContainer>
);

export default ImageFooterElement;
