export default function sendEmail(state){
    return fetch(`/api/inquire`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(state)
    }).then((res) => res.ok ? res : Promise.reject(res))
}
