import styled from 'styled-components';

const ArtworkImage = styled.img`
    width: auto;
    max-width: 100%;
    max-height: 70vh;
    
    @media all and (max-width: 800px) {
      max-height: 100%;
    }
`;

const ArtworkImageContainer = styled.div`
  text-align: center;
  flex: 1;
  height: 100%;
  margin: 0 80px;
  
  @media all and (max-width: 800px) {
    padding: 0;
    margin: 0;
  }
`;

const ImageFooterElementContainer = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  margin-right: 4px;
  cursor: pointer;
  
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    box-sizing: border-box;
    border: ${(props) => (props.active ? '#AE966A' : 'transparent')} 3px solid;
    z-index: ${((props) => (props.active ? 0 : -1))};
    transition: all .2s;
  }
  img {
    display: block;
  }
`;

const ImageFooterImg = styled.img`
  width: auto;
  height: 100%;
  transition: all .2s;
  
  &:hover {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.7);
  }
`;


const ImageFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px dotted #3A3A3A;

  & > div {
      margin: 4px;
      height: 80px;

      @media all and (max-width: 800px) {
          margin: 2px;
          height: 50px;
      }
  }
`;

export {
    ArtworkImage,
    ArtworkImageContainer,
    ImageFooterContainer,
    ImageFooterElementContainer,
    ImageFooterImg,
};
