import React from 'react';
import { Link } from 'gatsby';

import { NextArtworkMobile, PreviousArtworkMobile } from './styledElements';

const ListControlsMobile = ({ active, iconSource, link, type }) => {
    if (type === 'next') {
        return active
            ? (
                <NextArtworkMobile>
                    <Link to={link}>
                        <img src={iconSource} alt="Next"/>
                    </Link>
                </NextArtworkMobile>
            )
            : (
                <NextArtworkMobile disabled>
                    <img src={iconSource} alt="Next"/>
                </NextArtworkMobile>
            );
    }
    if (type === 'prev') {
        return active ? (
                <PreviousArtworkMobile>
                    <Link to={link}>
                        <img src={iconSource} alt="Previous"/>
                    </Link>
                </PreviousArtworkMobile>
            )
            : (
                <PreviousArtworkMobile disabled>
                    <img src={iconSource} alt="Next"/>
                </PreviousArtworkMobile>
            );
    }
    return null;
};

export default ListControlsMobile;
