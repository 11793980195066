import React, { useState } from 'react';

import ImageFooter from './ImageFooter';
import { ArtworkImage, ArtworkImageContainer } from './styledElements';

const Image = ({ imageSources }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <ArtworkImageContainer>
            <ArtworkImage
                src={imageSources[activeIndex]}
                alt="preview"
            />
            {imageSources.length > 1 && (
                <ImageFooter
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    imageSources={imageSources}
                />
            )}
        </ArtworkImageContainer>
    );
};

export default Image;
