import React, {useEffect, useState} from 'react';
import Artwork from '../components/Artwork';
import { graphql } from 'gatsby';
import slugify from "slugify";

export const query = graphql`
    query ArtworkPageQuery($artworkId: Int!, $exhibitionId: Int!) {
        arteiaBuild {
            artworks_by_pk(id: $artworkId) {
                id
                artist
                artwork_title
                date_of_creation
                technique
                signature
                dimensions
                provenance
                exhibitions
                literature
                image_file_name
                details_file_name
                price
                external_link
                additional_info
                currency
                status
                description
              }
              exhibitions_by_pk(id: $exhibitionId) {
                footer_text
                footer_phone
                assets_folder
                active
                title
                subtitle
                footer_credits
            }
        }
    }
`;


const ArtworkTemplate = ({ data, navigate, pageContext }) => {
    const {
        assetsPath,
        cdnPath,
        contactEmail,
        documentAssetsPath,
        entryAssetsPath,
        language,
        listSettings,
    } = pageContext;
    const {
        arteiaBuild: {
            artworks_by_pk: artwork,
            exhibitions_by_pk: exhibition,
        },
    } = data;
    const {
        nextArtworkSlug,
        previousArtworkSlug,
    } = listSettings;

    const [inquireOpened, setInquireOpened] = useState(false);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (!inquireOpened && event.key && event.key.toLowerCase() === 'arrowright') {
                // if (nextArtworkSlug) navigate(`/${language}/${nextArtworkSlug}`);
                if (nextArtworkSlug) navigate(`/${nextArtworkSlug}`);
            }
            if (!inquireOpened && event.key && event.key.toLowerCase() === 'arrowleft') {
                // if (previousArtworkSlug) navigate(`/${language}/${previousArtworkSlug}`);
                if (previousArtworkSlug) navigate(`/${previousArtworkSlug}`);
            }
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', handleKeyPress);
            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            }
        }
    }, [language, nextArtworkSlug, previousArtworkSlug, navigate, inquireOpened]);

    return (
        <Artwork
            artwork={artwork}
            assetsPath={assetsPath}
            cdnPath={cdnPath}
            contactEmail={contactEmail}
            documentAssetsPath={documentAssetsPath}
            entryAssetsPath={entryAssetsPath}
            exhibition={exhibition}
            listSettings={listSettings}
            language={language}
            disableArrowNav={setInquireOpened}
            backPath={exhibition.active ? `/` : `/${slugify(exhibition.title + ' ' + exhibition.subtitle, { lower: true })}`}
        />
    );
};

export default ArtworkTemplate;
