import React from 'react';
import { Link } from 'gatsby';

import { NextArtworkDesktop, PreviousArtworkDesktop } from './styledElements';

const ListControlsDesktop = ({ active, iconSource, link, type }) => {
    if (type === 'next') {
        return active
            ? (
                <NextArtworkDesktop>
                    <Link to={link}>
                        <img src={iconSource} alt="Next"/>
                    </Link>
                </NextArtworkDesktop>
            )
            : (
                <NextArtworkDesktop disabled>
                    <img src={iconSource} alt="Next"/>
                </NextArtworkDesktop>
            );
    }
    if (type === 'prev') {
        return active ? (
                <PreviousArtworkDesktop>
                    <Link to={link}>
                        <img src={iconSource} alt="Previous"/>
                    </Link>
                </PreviousArtworkDesktop>
            )
            : (
                <PreviousArtworkDesktop disabled>
                    <img src={iconSource} alt="Next"/>
                </PreviousArtworkDesktop>
            );
    }
    return null;
};

export default ListControlsDesktop;
