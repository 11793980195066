import React from 'react';

import ImageFooterElement from './ImageFooterElement';
import { ImageFooterContainer } from './styledElements';

const ImageFooter = ({ activeIndex, imageSources, setActiveIndex }) => (
    <ImageFooterContainer>
        {imageSources.map((src, index) => (
            <ImageFooterElement
                key={index}
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
                src={src}
            />
        ))}
    </ImageFooterContainer>
);

export default ImageFooter;
