import React from 'react';
import { Link } from 'gatsby';

import {
    ArtworkContentHeader,
    ActivePageIndexIndicator,
    PageIndexContainer,
    RightControlsContainer,
    TotalPagesIndicator,
} from './styledElements';
import ListControlsMobile from './ListControlsMobile';

const Header = ({
                    artworkIndex,
                    artworksTotal,
                    entryAssetsPath,
                    language,
                    nextArtworkSlug,
                    previousArtworkSlug,
                    backPath,
                }) => {
    const rightArrowSource = `${entryAssetsPath}/ico_right.svg`;
    const leftArrowSource = `${entryAssetsPath}/ico_left.svg`;

    const nextArtworkLink = `/${nextArtworkSlug}`;
    const hasNext = Boolean(nextArtworkSlug);

    const previousArtworkLink = `/${previousArtworkSlug}`;
    const hasPrevious = Boolean(previousArtworkSlug);

    return (
        <ArtworkContentHeader>
            <Link to={backPath}>
                {'< Back to list'}
            </Link>
            <RightControlsContainer>
                <ListControlsMobile
                    type={'prev'}
                    active={hasPrevious}
                    iconSource={leftArrowSource}
                    link={previousArtworkLink}
                />
                <PageIndexContainer>
                    <ActivePageIndexIndicator>
                        {`${artworkIndex + 1}`}
                    </ActivePageIndexIndicator>
                    <TotalPagesIndicator>
                        {` / ${artworksTotal}`}
                    </TotalPagesIndicator>
                </PageIndexContainer>
                <ListControlsMobile
                    type={'next'}
                    active={hasNext}
                    iconSource={rightArrowSource}
                    link={nextArtworkLink}
                />
            </RightControlsContainer>
        </ArtworkContentHeader>
    );
};

export default Header;
